const myOperationsModal = document.getElementById("my-operations");
const selectBookingModal = app.querySelector('#select-booking');

const myOperationsButton = document.getElementById("my-operations-button");
myOperationsButton.addEventListener("click", async () => {
  await toggleMainMenu('show'); // Скрываем все элементы, кроме главного меню
  selectBookingModal.classList.add("hidden");
  myOperationsModal.classList.remove("hidden");
  goToMainMenuBtn.classList.remove('hidden');


  // Если есть непрочитанные операции, то обновляем список прочитанных операций и убираем индикатор
  const allOperations = JSON.parse(localStorage.getItem('operations')) || [];
  localStorage.setItem('readOperationIds', JSON.stringify(allOperations.map(operation => operation.id)));
  refreshNotificationsCounter();
});

